import React from "react"
import "./page.scss"
import Footer from "./footer"

const Page = ({ children, context }) => {
  return (
    <>
      <h3 className="title">
        <img style={{width: '4vw', verticalAlign: 'bottom', margin: '0 5px'}} alt="fossdef.org icon" src="/img/freepik-firewall-icon.png" />
        Free & Open Source Defense Fund
      </h3>
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default Page
