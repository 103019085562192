import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const Footer = () => {
  const { site } = useStaticQuery(
    graphql`query {
      site {
        siteMetadata {
          author
        }
      }
    }`
  )

  return (
  <div style={{textAlign: 'center', marginTop: '8vmin'}}>
    <div>
      &copy; {new Date().getFullYear()} {site.siteMetadata.author}, All Rights Reserved
    </div>
    <div style={{fontSize: '0.8em'}}>
      <a href="https://www.flaticon.com/free-icons/anti-virus-shield">Shield Icon created by Freepik - Flaticon</a>
    </div>
  </div>
  )
}

export default Footer
